import {BrowserModule} from '@angular/platform-browser'
import {NgModule} from '@angular/core'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'

import {registerLocaleData} from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import localeFrExtra from '@angular/common/locales/extra/fr'
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core'
import {LuxonDateAdapter, MAT_LUXON_DATE_FORMATS} from '@angular/material-luxon-adapter'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {SpbCommonModule} from './common/common.module'
import {CustomMaterialsModule} from './custom-materials-module'
import {AppComponent} from './app.component'
import {AppRoutingModule} from './app-routing.module'
import {ResponseInterceptor} from './application/response-interceptor'
import {AuthInterceptor} from './application/auth-interceptor'

registerLocaleData(localeFr, 'fr', localeFrExtra)


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CustomMaterialsModule,
    HttpClientModule,
    SpbCommonModule,
  ],
  exports: [],
  providers: [
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS,
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always', hideRequiredMarker: true}},
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
