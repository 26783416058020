/**
 * This service is intended to be used to share some application
 * state...
 */
import {Injectable, NgZone} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {Router} from '@angular/router'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../environments/environment'

// eslint-disable-next-line no-shadow
export enum ApplicationViews {
  OFFICE, // Kontor, dvs bankkontor
  CREDIT, // Kreditavdelning
  INTERNAL_SUPPORT, // Internstöd
  CUSTOMER_SUPPORT // Kundstöd
}

export interface ApplicationState {

  /**
   * A string location outside the main view. E.g. bulk or stats
   */
  location: string

  /**
   * Set the main view filter
   */
  view: ApplicationViews

  /**
   * Tells if we want list or grid view in defectTypes
   */
  defectListView: boolean

}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  /**
   * The initial state of the application
   */
  public state: ApplicationState = {
    location: 'base',
    view: ApplicationViews.INTERNAL_SUPPORT,
    defectListView: true,
  }

  /**
   * The application state as a behavior subject.
   */
  public applicationState = new BehaviorSubject<ApplicationState>(this.state)

  private backendVersion

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private httpClient: HttpClient,
  ) {
    // Subscribe to our own state so that we can update
    this.applicationState.subscribe({
      next: (state: ApplicationState) => {
        this.state = state
      }
    })
  }

  public bootStrap(): void {
    const url = `${environment.defectServiceUrl}/version`
    this.httpClient.get<any>(url).subscribe((version) => this.backendVersion = version)
  }

  /**
   * Someone call this to navigate
   */
  public navigate(location: string): any | void {

    this.state.location = location
    this.applicationState.next(this.state)

    if (location === 'bulk') {
      return this.ngZone.run(() => {
        this.router.navigate(['/base/bulk'])
      })
    }

    if (location === 'start') {
      return this.ngZone.run(() => this.router.navigate(['/']))
    }

    if (location === 'reports') {
      return this.ngZone.run(() => this.router.navigate(['/base/reports']))
    }

    return this.ngZone.run(() => this.router.navigate(['/base']))
  }

  public selectView(view: ApplicationViews): void {
    this.state.view = view
    this.applicationState.next(this.state)
  }

  public setListView(list: boolean): void {
    this.state.defectListView = list
    this.applicationState.next(this.state)
  }
}
