import {Component, OnInit} from '@angular/core'
import {DefectService} from '../../services/defect.service'
import {Defect} from '../../services/types'

interface IKPI {
  kpi: number

  date: number

  endDate: number

  fileCount: number

  files?: any

  defects?: Defect[]


}

interface IStatistics {

  files: any

  defects: IStatisticsDefects

  kpi: IKPI

}

interface IStatisticsDefects {

  count: number

  defectTypes: [any]

  severities: [any]
}

@Component({
  selector: 'spb-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  public files

  public defects

  public kpi: IKPI

  constructor(private defectService: DefectService) {
  }

  ngOnInit() {
    this.defectService.getStatistics().subscribe((statistics: IStatistics) => {
      this.files = statistics.files
      this.defects = statistics.defects
      this.kpi = statistics.kpi
    })
  }
}
