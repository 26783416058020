import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core'

@Directive({
  selector: '[spb-user-info]'
})
export class UserInfoDirective {

  constructor(private el: ElementRef,
              private renderer: Renderer2) {
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.el.nativeElement.classList.add('show-user-info')
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.renderer.removeClass(this.el.nativeElement, 'show-user-info')
  }

}
