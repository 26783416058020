import {Component} from '@angular/core'

@Component({
  selector: 'spb-footer',
  template: `
    <div class="contact">
      <div>
      </div>
    </div>
    <div class="bottom-foot">
      <div>All Rights Reserved Sparbanken Syd © 2020 - 2022</div>
    </div>
  `,
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
}
