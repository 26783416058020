import {Inject, Injectable, NgZone} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, Observable, Subject, timer} from 'rxjs'
import {mergeMap, takeUntil} from 'rxjs/operators'
import {Router} from '@angular/router'
import {environment} from '../../environments/environment'
import {LOCAL_STORAGE} from '../application/localstorage.provider'

/**
 * Simple interface for starting a login
 */
export interface StartLoginResponse {

  /**
   * Something something we have to send to BankId
   */
  autoStartToken: string

  /**
   * Something something we have to send to BankId
   */
  orderRef: string
}

/**
 * Whatever we get when calling for status
 */
export interface CollectResponse {

  /**
   * The order reference
   */
  orderRef: string

  /**
   * The status of the order
   */
  status: string

  /**
   * A hint of what went wrong
   */
  hintCode?: string

  /**
   * The actual result of the operation
   */
  completionData?: CompletionData

  /**
   * The access token, in form of a jwt to use for further access
   */
  accessToken: string

  /**
   * Present if something is broken
   */
  errorCode: string
}

/**
 * This is what we get when login is complete
 */
export interface CompletionData {
  /**
   * The user as specified by BankId
   */
  user: BankIdUser
}

/**
 * The user information from BankID
 */
export interface BankIdUser {

  /**
   * The personal number
   */
  personalNumber: string

  /**
   * User given name/first name
   */
  givenName: string

  /**
   * User last name or surname or family name.
   */
  surName: string
}

/**
 * Indicates if we are even trying to log in
 */
// eslint-disable-next-line no-shadow
export enum LoggedInStatus {
  NOT_STARTED,
  STARTED,
  IN_PROGRESS,
  FAILED,
  COMPLETE
}

/**
 * The idea is that lower number is more access so that we can say role < 3 etc.
 * May or may not work.
 */

// eslint-disable-next-line no-shadow
export enum FileMonitoringRole {
  ADMIN = 1,
  CREDIT = 2,
  INTERNAL_SUPPORT = 3,
  CUSTOMER_SUPPORT = 4,
  OFFICE = 5,
  EMPLOYEE = 6,
  TESTER = 7,
  FM_ADMIN = 8,
  BLANCO_ADMIN = 9
}

/**
 * The logged in state, either logged in or not.
 */
export interface LoggedInState {
  /**
   * The logged in state
   */
  loggedIn: boolean

  /**
   * If we want to communicate status somehow we do this in this property.
   */
  statusMessage?: string

  /**
   * Where we are in the process of logging in.
   */
  state: LoggedInStatus

  /**
   * If login fails this message will be set to something sensible.
   */
  errorMessage?: string

  /**
   * More detailed error information
   */
  errorDetail?: string

  /**
   * The access token as such
   */
  accessToken?: string

  /**
   * An array of roles
   */
  roles?: Array<FileMonitoringRole>

  /**
   * Tells if the interceptor is ready to serve the authToken,
   * Check this if you only care about making requests.
   */
  interceptorReady?: boolean
}


@Injectable({
  providedIn: 'root',
})
export class LoginService {
  /**
   * What is best for this?
   */
  private static START_LOGIN_MESSAGE = 'Påbörjar inlogging'

  /**
   * Message to send when login is completado.
   */
  private static LOGIN_COMPLETE_MESSAGE = 'Inloggning klar'

  /**
   * Message to send when login is los broken.
   */
  private static LOGIN_FAILED_MESSAGE = 'Inloggning misslyckades'

  /**
   * Error message when a bad personnummer is passed.
   */
  private static BANKID_PERSONNUMMER_ERROR = 'Personnumret stämmer inte, kontrollera och försök igen.'

  /**
   * Error message displayed when admin is required.
   */
  private static ADMINISTRATORS_ONLY = 'Denna tjänst är under utveckling och kan endast användas av utvalda personer.'

  /**
   * Error message when backend fails for other reason.
   */
  private static BANKID_GENERAL_ERROR = 'Något gick fel här, osäkert vad... Vi kollar på det ' +
    'och ber dig återkomma om en stund.'
  /**
   * Subscribe to the logged in state through a subscription.
   * We initiate as false so that we do not believe falsy
   */
  public loggedInState = new BehaviorSubject<LoggedInState>({loggedIn: false, state: LoggedInStatus.NOT_STARTED})

  /**
   * What we name what we store in the localstorage.
   */
  private ACCESS_TOKEN_NAME = 'fmAppAccessToken'

  /**
   * Message to be sent when we start log in.
   */
  private START_LOGIN_STATUS: LoggedInState = {
    loggedIn: undefined,
    state: LoggedInStatus.STARTED,
    statusMessage: LoginService.START_LOGIN_MESSAGE,
  }
  /**
   * Message to be sent when we collect data.
   */
  private LOGIN_IN_PROGRESS_STATUS: LoggedInState = {
    loggedIn: undefined,
    state: LoggedInStatus.IN_PROGRESS,
  }
  /**
   * Message to send when login fails
   */
  private LOGIN_FAILED_STATUS: LoggedInState = {
    loggedIn: false,
    state: LoggedInStatus.FAILED,
    statusMessage: LoginService.LOGIN_FAILED_MESSAGE,
  }
  /**
   * A bunch of login variables that we need to reuse?
   */
  private roles: []


  /**
   * We need a client
   *
   * @param httpClient - The HTTP client
   * @param router - To route to home if login breaks.
   * @param ngZone - To route withing subscription
   * @param injectedLocalStorage - So that we can mock localstorage.
   */
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private ngZone: NgZone,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage,
  ) {
    this.checkLoggedInStatus()
  }

  public logOut() {
    this.injectedLocalStorage.removeItem(this.ACCESS_TOKEN_NAME)
    this.checkLoggedInStatus()
    return this.ngZone.run(() => {
      this.router.navigate(['/']).then()
    })
  }

  /**
   * Fetch and validate an access token. If ok send login complete status.
   * This can be called by outsiders to retrieve status if they were not
   * in play when the message was sent last time.
   */
  public checkLoggedInStatus(): boolean {
    let loggedIn = false
    let roles = []

    const accessToken = this.injectedLocalStorage.getItem(this.ACCESS_TOKEN_NAME)
    if (accessToken) {
      const payload = JSON.parse(atob(accessToken.split('.')[1]))
      let now = new Date().getTime()
      // Migrating to ms times. If ~today * 10 then we have short format.
      if (payload.exp < 15941421100) {
        now = Math.floor(now / 1000)
      }
      loggedIn = now < payload.exp
      //
      // What we want to do here is to convert the roles ['admin', 'credit'] to numbers that we
      // use in the app [0,1], so we create a map with keys and then fetch from the map with
      // strings ('admin' etc.) that we find in the payload.
      const roleMap = {
        admin: FileMonitoringRole.ADMIN,
        credit: FileMonitoringRole.CREDIT,
        internalSupport: FileMonitoringRole.INTERNAL_SUPPORT,
        customerSupport: FileMonitoringRole.CUSTOMER_SUPPORT,
        office: FileMonitoringRole.OFFICE,
        employee: FileMonitoringRole.EMPLOYEE,
        tester: FileMonitoringRole.TESTER,
        fmAdmin: FileMonitoringRole.FM_ADMIN,
      }
      // The filter is b/c the user may have other roles not present in this application
      roles = payload.roles.map((role) => roleMap[role]).filter((i) => i !== undefined)
      this.roles = roles as any
    }
    if (loggedIn) {
      this.loggedInState.next({
        loggedIn: false,
        state: LoggedInStatus.IN_PROGRESS,
        accessToken,
        roles,
      })
    } else {
      this.injectedLocalStorage.removeItem(this.ACCESS_TOKEN_NAME)
      this.loggedInState.next({
        loggedIn,
        state: LoggedInStatus.NOT_STARTED,
      })
    }
    return loggedIn
  }

  /**
   * This is used by the interceptor to signal that it is ready
   * to recevie authed-requests. Until we get that we simply
   * do not proceed to logged in state.
   */
  public interceptorReady(ready: boolean): void {
    this.loggedInState.next({
      loggedIn: true,
      state: LoggedInStatus.COMPLETE,
      statusMessage: LoginService.LOGIN_COMPLETE_MESSAGE,
      interceptorReady: ready,
      roles: this.roles
    })
  }

  /**
   * Tries to login a user based on personnummer. Will continuously present status on
   * the loggedInState subject.
   */
  public login(personNummer: string): void {
    if (this.checkLoggedInStatus()) {
      return
    }

    let orderRef: string
    const stopCountDown = new Subject<any>()
    this.loggedInState.next(this.START_LOGIN_STATUS)

    this.startLogin(personNummer).pipe(
      mergeMap((startResponse: StartLoginResponse) => {
        orderRef = startResponse.orderRef
        return timer(0, 2000)
      }),
      takeUntil(stopCountDown),
      mergeMap(() => this.collect(orderRef)),
    ).subscribe({
        next:
          (res: CollectResponse) => {
            if (res.accessToken) {
              stopCountDown.next() // Completes the countdown
              this.setLoggedIn(res.accessToken)
              return // Make sure to return so that we do not send updates.
            }
            this.LOGIN_IN_PROGRESS_STATUS.statusMessage = res.hintCode
            this.loggedInState.next(this.LOGIN_IN_PROGRESS_STATUS)
          },
        error: (error) => {
          if (error.status === 400) {
            this.LOGIN_FAILED_STATUS.errorMessage = LoginService.BANKID_PERSONNUMMER_ERROR
            this.LOGIN_FAILED_STATUS.errorDetail = error.error.errorMessage
          } else if (error.status === 403) {
            this.LOGIN_FAILED_STATUS.errorMessage = LoginService.ADMINISTRATORS_ONLY
            this.LOGIN_FAILED_STATUS.errorDetail = error.error.errorMessage
          } else {
            this.LOGIN_FAILED_STATUS.errorMessage = LoginService.BANKID_GENERAL_ERROR
            this.LOGIN_FAILED_STATUS.errorDetail = error.error.errorMessage
          }
          this.loggedInState.next(this.LOGIN_FAILED_STATUS)
        }
      }
    )
  }

  /**
   * Collect responses.
   */
  public collect(orderRef: string): Observable<CollectResponse> {
    const url = `${environment.loginServiceUrl}/login/collect?orderRef=${orderRef}`
    return this.httpClient.get<CollectResponse>(url)
  }

  /**
   * Start the login, this is towards the common directory.
   */
  private startLogin(personnummer: string): Observable<StartLoginResponse> {
    const data = {
      personnummer,
      domain: environment.domain,
      type: 'auth',
      scope: 'auth'
    }
    const url = `${environment.loginServiceUrl}/login/start`
    return this.httpClient.put<StartLoginResponse>(url, data)
  }

  /**
   * Saves and communicates new logged in status
   */
  private setLoggedIn(accessToken: string): void {
    this.saveAccessToken(accessToken)
    this.checkLoggedInStatus()
  }

  /**
   * Set the authentication token in local storage.
   *
   * @param token - The token as received from the login service
   */
  private saveAccessToken(token: string): void {
    this.injectedLocalStorage.setItem(this.ACCESS_TOKEN_NAME, token)
  }
}
