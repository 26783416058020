import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {map} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {LoginService} from './login.service'
import {User} from './types'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * Consumers should listen to this to get latest user list.
   */
  public users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([])
  /**
   * Our private list of users that we do not show.
   */
  private userList: any []
  /**
   * This current user (/users/self) is only updated when page is reloaded.
   *
   */
  private currentUser: User

  /**
   * @param httpClient - To gain some https access.
   * @param loginService - To listen to logged in events to be able to clear caches.
   */
  constructor(
    private httpClient: HttpClient,
    private loginService: LoginService,
  ) {
    this.getUsers()
    this.loginService.loggedInState.subscribe((/*state: LoggedInState*/) => {
      // For any change in login we simply reset our cached data
      this.currentUser = undefined
    })

  }

  public getUsers(update?: boolean): void {
    let url = `${environment.userServiceUrl}/users`
    if (update === true) {
      url += `?bust=${new Date().getTime()}`
    }
    this.httpClient.get<Array<User>>(url).subscribe({
      next: ((users) => {
        this.userList = users
        this.users.next(this.userList)
      })
    })
  }

  /**
   * Randomly update or create users.
   */
  public updateUser(user: User): void {
    const url = `${environment.userServiceUrl}/users`
    this.httpClient.put<User>(url, user)
      .subscribe((updatedUser: User) => {
        let newUser = true
        this.userList = this.userList.map((u: User) => {
          if (u.itemId === updatedUser.itemId) {
            newUser = false
            return updatedUser
          }
          return u
        })
        if (newUser) {
          this.userList.push(updatedUser)
        }
        this.users.next(this.userList)
      })
  }

  public getUser(sId: string): User {
    return this.userList ? this.userList.find((user) => user.sId === sId) : {}
  }

  public getCurrentUser(): Observable<User> {
    if (this.currentUser) {
      return of(this.currentUser)
    }
    const url = `${environment.userServiceUrl}/users/self`
    return this.httpClient.get<User>(url)
      .pipe(map((user) => this.currentUser = user))
  }

  public deleteUser(id: string): Observable<void> {
    const url = `${environment.userServiceUrl}/users/${id}`
    return this.httpClient.delete<void>(url)
  }
}
