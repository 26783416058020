import {Injectable} from '@angular/core'
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {catchError} from 'rxjs/operators'
import {throwError} from 'rxjs'
import {LoginService} from '../services/login.service'

/**
 * Intended to catch e.g. 401 responses and if so re-route to home.
 */
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(
    private loginService: LoginService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // console.log('NW? Error', error)
        // console.log('NW? Error status', error.status)
        if (error.status === 401) {
          this.loginService.logOut()
        }
        if (error.error instanceof ErrorEvent) {
          // console.log('instance of?')
          // console.log(`Error: ${error.error.message}`)
        } else {
          // client-side error
          // console.log(`CLIENT ? Error Code: ${error.status}\nMessage: ${error.message}`)
        }
        return throwError(error)
      })
    )
  }
}
