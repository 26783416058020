import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {Subscription} from 'rxjs'
import {ApplicationService, ApplicationState, ApplicationViews} from '../../services/application.service'
import {LoginService} from '../../services/login.service'
import {UserService} from '../../services/user.service'
import {User} from '../../services/types'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   *
   */
  @Output() application = new EventEmitter()

  /**
   * We have to export this to the template;
   */
  public applicationViews = ApplicationViews

  /**
   * View name to display,
   */
  public viewName: string
  /**
   * The currently logged in user.
   */
  public user: User = {} as any

  /**
   * Tells if admin functions are available.
   */
  public isAdmin = false

  /**
   * Tells if Tester functions are available.
   */
  public isTester = false


  /**
   * Should we show the bulk import or not?
   */
  public bulkView = false

  /**
   * Should we show the file view or not?
   */
  public fileView = false

  /**
   * Subscribe to application state changes.
   */
  private application$: Subscription
  /**
   * Subscribe to logged in state changes.
   */
  private login$: Subscription

  /**
   * The constructor
   *
   * @param loginService - To log out.
   * @param userService - To fetch current user.
   * @param applicationService - The application service to update with state.
   */
  constructor(
    private loginService: LoginService,
    private userService: UserService,
    public applicationService: ApplicationService,
  ) {
  }

  /**
   *
   */
  ngOnInit() {
    this.application$ = this.applicationService.applicationState.subscribe({
      next: (state: ApplicationState) => {
        this.viewName = ['Kontor', 'Kredit', 'Internstöd', 'Kundstöd'][state.view]
        this.bulkView = state.view === ApplicationViews.CREDIT || state.view === ApplicationViews.INTERNAL_SUPPORT
        this.fileView = state.view === ApplicationViews.CREDIT ||
          state.view === ApplicationViews.INTERNAL_SUPPORT ||
          state.view === ApplicationViews.CUSTOMER_SUPPORT
      }
    })


    this.login$ = this.loginService.loggedInState.subscribe({
      next: ((state) => {
        if (state.loggedIn !== true) {
          this.user = {} as any
          return
        }

        if (this.user.name) {
          return
        }

        /**
         * Http observables need not be unsubscribed from.
         */
        this.userService.getCurrentUser().subscribe({
          next: (user: User) => {
            this.user = user
            this.isAdmin = this.user.roles.indexOf('fmAdmin') !== -1
            this.isTester = this.user.roles.indexOf('tester') !== -1
          }
        })
      })
    })

  }

  /**
   * Remember to unsubscribe to avoid leakage.
   */
  ngOnDestroy() {
    this.application$.unsubscribe()
    this.login$.unsubscribe()
  }

  logOut(): void {
    this.loginService.logOut()
  }
}
