<div *ngIf="defects && files" class="stat">
  <div class="stat-card mat-elevation-z8">
    <h3>Fel och Brister</h3>
    <div class="stat-line-item">
      <div>Antal</div>
      <div>{{defects.count}}</div>
    </div>
    <div *ngFor="let sev of defects.severities" class="stat-line-item">
      <div> {{sev.name}}</div>
      <div> {{sev.value}}</div>
    </div>
  </div>
  <div *ngIf="files" class="stat-card mat-elevation-z8">
    <h3>Aktstatus</h3>
    <div class="stat-line-item">
      <div>Ej levererade&nbsp;&nbsp;&nbsp;</div>
      <div>{{files.delayed.count}}</div>
    </div>
    <div class="stat-line-item">
      <div>Öppna</div>
      <div>{{files.arrived.count}}</div>
    </div>
    <div class="stat-line-item">
      <div>Stängda</div>
      <div>{{files.closed.count}}</div>
    </div>
  </div>

  <div class="stat-card mat-elevation-z8">
    <h3>Antal akter med fel/brist</h3>
    <div class="stat-count">{{defects.files.length}}</div>
  </div>

  <div class="stat-card mat-elevation-z8">
    <h3>Styrkort</h3>
    <div class="stat-count">{{kpi.kpi * 100 | number:'1.1-1'}} %</div>
  </div>

  <div *ngIf="files" class="stat-card mat-elevation-z8">
    <h3>KPI-beräkning</h3>
    <div class="stat-line-item">
      <div>Från</div>
      <div>{{kpi.date | date:'yyyy-MM-dd'}}</div>
    </div>
    <div class="stat-line-item">
      <div>Till</div>
      <div>{{kpi.endDate - (2 * 3600 * 1000) | date:'yyyy-MM-dd'}}</div>
    </div>
    <div class="stat-line-item">
      <div>Antal allvarliga fel</div>
      <div>{{kpi.defects.length}}</div>
    </div>
    <div class="stat-line-item">
      <div>Antal akter som skapats denna månad</div>
      <div>{{kpi.fileCount}}</div>
    </div>
  </div>

  <mat-expansion-panel *ngIf="kpi" class="stat-card mat-elevation-z8">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3>Akter som ingår i beräkningen</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let file of kpi.files" class="kpi-item">
      <div>{{file.timeStamp | date:'yyyy-MM-dd'}}</div>
      <div>{{file.fileId}}</div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="kpi" class="stat-card mat-elevation-z8">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3>Brister/Fel/Allv. Fel som ingår i beräkningen</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let defect of kpi.defects" class="kpi-item">
      <div>{{defect.timeStamp | date:'yyyy-MM-dd'}}</div>
      <div>{{defect.fileId}}</div>
      <div>{{defect.defectType.description}}</div>
      <div>{{defect.defectType.severity.name}}</div>
    </div>
  </mat-expansion-panel>
</div>
