<div *ngIf="state !== 'waiting' " class="mat-typography">
  <div *ngIf="state === 'error'" class="spb-error">
    {{this.errorMessage}}
  </div>
  <form #f="ngForm" (ngSubmit)="login()">
    <div class="input">
      <mat-form-field class="" floatLabel="always" hideRequiredMarker>
        <mat-label>Personnummer</mat-label>
        <label for="personNummer" [hidden]="true"></label>
        <input
          id="personNummer"
          matInput
          placeholder="ÅÅÅÅMMDDNNNN"
          [(ngModel)]="personNummer"
          maxlength="13"
          minlength="10"
          name="personNummer"
          type="tel"
          required/>
      </mat-form-field>
      <button
        mat-raised-button color="primary"
        [disabled]="state === 'waiting' || !f.form.valid || disableInput">
        {{label}}
      </button>
    </div>
  </form>
</div>
<spb-backdrop *ngIf="state ==='waiting'" [text]="statusMessage"></spb-backdrop>
