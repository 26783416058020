<div>
  <div class="top-holder">
    <div class="top">
      <div>{{user.name}} ({{viewName}})</div>
    </div>
  </div>
  <div class="middle-holder">
    <div class="middle">
      <div class="logo">
        Aktbevakning
      </div>
      <div class="menu">
        <div (click)="applicationService.navigate('defectTypes')" *ngIf="fileView">Aktvy</div>
        <div (click)="applicationService.navigate('bulk')" *ngIf="bulkView">Ank. reg.</div>
        <div (click)="applicationService.navigate('reports')">Rapporter</div>
        <div (click)="applicationService.navigate('start')"></div>
      </div>
      <div class="spb-filler"></div>
      <div *ngIf="isAdmin || isTester" class="view-name">{{viewName}}</div>
      <div class="menu-button">
        <button [matMenuTriggerFor]="menu" mat-button>
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button *ngIf="isAdmin || isTester" [matMenuTriggerFor]="views" mat-menu-item>Vy</button>
          <button *ngIf="isAdmin" [routerLink]="['/admin']" mat-menu-item>Admin</button>
          <button (click)="logOut()" mat-menu-item>Logga ut</button>
        </mat-menu>
        <mat-menu #views="matMenu">
          <button (click)="applicationService.selectView(applicationViews.INTERNAL_SUPPORT)"
                  mat-menu-item>
            Internstöd
          </button>
          <button (click)="applicationService.selectView(applicationViews.CREDIT)"
                  mat-menu-item>
            Kredit
          </button>
          <button (click)="applicationService.selectView(applicationViews.CUSTOMER_SUPPORT)"
                  mat-menu-item>
            Kundstöd
          </button>
          <button (click)="applicationService.selectView(applicationViews.OFFICE)"
                  mat-menu-item>
            Kontor
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
