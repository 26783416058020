import {Directive, ElementRef, Input, OnInit} from '@angular/core'

@Directive({
  selector: '[spb-severity-class]',
})
export class SeverityDirective implements OnInit {
  @Input('spb-severity-class') severity: number

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    let color = 'black'
    switch (this.severity) {
      case 0:
        color = 'red'
        break
      case 1:
        color = 'orange'
        break
      case 2:
        color = 'green'
        break
    }
    this.el.nativeElement.style.color = color
  }
}
