import {Component, Input, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {LoggedInState, LoginService} from '../../services/login.service'

/**
 * Calls BankID as necessary and when done (or failed)
 * it emits an event that (someone) can listen to.
 */
@Component({
  selector: 'spb-bankid',
  templateUrl: './bankid.component.html',
  styleUrls: ['./bankid.component.scss']
})
export class BankidComponent implements OnInit, OnDestroy {

  /**
   * The personnummer to use when logging in.
   */
  @Input() public personNummer: string

  /**
   * This is so that you can set the button label as you wish.
   */
  @Input() public label = 'Logga in'

  /**
   * External control over the submit button.
   */
  @Input() public disableInput = false

  /**
   * Current state, enums are useless since they do not work
   * inside the template anyhows.
   */
  public state = 'input'

  /**
   *
   */
  public statusMessage = 'Starta BankID-applikationen på den enhet du har det installerat.'

  /**
   * A general error message
   */
  public errorMessage = ''

  /**
   * Details of the error.
   */
  public errorDetail = ''

  /**
   * Holds the subscription of the login state.
   */
  private login$: Subscription

  /**
   * Default constructor
   */
  constructor(
    private loginService: LoginService,
  ) {
  }

  /**
   * Subscribe to the login state. Used to control state.
   */
  ngOnInit(): void {
    this.login$ = this.loginService.loggedInState.subscribe((state: LoggedInState) => {
      if (state.loggedIn === true) {
        this.state = 'input'
      }
      if (state.errorMessage) {
        this.state = 'error'
        this.errorMessage = state.errorMessage
        this.errorDetail = state.errorDetail
      }
    })
  }

  /**
   * Avoid leaking subscriptions.
   */
  ngOnDestroy(): void {
    this.login$.unsubscribe()
  }

  /**
   * Set wait state and try to login.
   */
  login(): void {
    this.state = 'waiting'
    this.loginService.login(this.personNummer)
  }
}
