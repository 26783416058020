import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {FormsModule} from '@angular/forms'
import {RouterModule} from '@angular/router'
import {MatExpansionModule} from '@angular/material/expansion'
import {CustomMaterialsModule} from '../custom-materials-module'
import {SeverityDirective} from '../application/severity.directive'
import {UserInfoDirective} from '../application/user-info.directive'
import {HeaderComponent} from './header/header.component'
import {FooterComponent} from './footer/footer.component'
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component'
import {BankidComponent} from './bankid/bankid.component'
import {BackdropComponent} from './backdrop/backdrop.component'
import {FileItemComponent} from './file-item/file-item.component'
import {UserInfoComponent} from './user-info/user-info.component'
import {StatisticsComponent} from './statistics/statistics.component'
import {CustomerNoDirective} from './directives/customer-no/customer-no.directive'

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    BankidComponent,
    BackdropComponent,
    FileItemComponent,
    SeverityDirective,
    UserInfoComponent,
    UserInfoDirective,
    StatisticsComponent,
    CustomerNoDirective
  ],
  imports: [
    CommonModule,
    CustomMaterialsModule,
    FormsModule,
    RouterModule,
    MatExpansionModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    BankidComponent,
    BackdropComponent,
    FileItemComponent,
    SeverityDirective,
    UserInfoDirective,
    UserInfoComponent,
    StatisticsComponent,
    CustomerNoDirective
  ],
})
export class SpbCommonModule {
}
