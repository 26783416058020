import {Component, Input, OnInit} from '@angular/core'
import {UserService} from '../../services/user.service'
import {User} from '../../services/types'

@Component({
  selector: 'spb-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  @Input() userId: string

  public user: User = {} as any

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.user = this.userService.getUser(this.userId)
  }
}
