import {Directive} from '@angular/core'
import {AbstractControl, FormControl, ValidationErrors, Validator, ValidatorFn} from '@angular/forms'

/**
 * Calculates the expected luhn.
 *
 * @param customerNo - The personnummer without century and no luhn, id est 9
 * @return The Luhn check digit.
 */
const getLuhn = (customerNo: string): number => {
  let v = 0
  let sum = 0

  for (let i = 0, l = customerNo.length; i < l; i++) {
    v = Number.parseInt(customerNo[i], 10)
    v *= 2 - (i % 2)
    if (v > 9) {
      v -= 9
    }
    sum += v
  }
  return (Math.ceil(sum / 10) * 10 - sum)
}

export const customerNoValidator: ValidatorFn = (fg: FormControl): ValidationErrors | null => {
  if (!fg.value) {
    return null
  }
  const reg = /^(\d{2})?(\d{2})(\d{2})(\d{2})([-|+]?)?(\d{3})(\d?)$/
  const match = reg.exec(fg.value.trim())
  // This is like not a customer number, let us accept it.
  if (!match) {
    return null
  }

  const year = match[2]
  const month = match[3]
  const day = match[4]
  const num = match[6]
  const check = match[7]

  // const check = Number.parseInt(customerNo[9])
  const luhn = getLuhn(year + month + day + num)
  if (Number.parseInt(check, 10) !== luhn) {
    return {
      error: `Luhn not correct (${check} != ${luhn})`
    }
  }
  return null
}

@Directive({
  selector: '[spb-customer-no]'
})
export class CustomerNoDirective implements Validator {

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return customerNoValidator(control)
  }

}
