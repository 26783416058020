<div class="wrapper" [style.fontSize.px]="16">
  <mat-form-field class="full-width" floatLabel="always">
    <mat-label>
      {{label}}
    </mat-label>
    <label>
      <input *ngIf="stringValue" [ngModel]="stringValue" matInput readonly>
    </label>
    <label>
      <input *ngIf="dateValue" [ngModel]="dateValue | date: 'yyyy-MM-dd'" matInput readonly>
    </label>
    <label>
      <input *ngIf="!dateValue && !numberValue && !stringValue" [value]="'Ingen Uppgift'" matInput readonly>
    </label>
  </mat-form-field>
</div>
