import {Injectable} from '@angular/core'
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {LoggedInState, LoggedInStatus, LoginService} from '../services/login.service'

/**
 * Inject to set auth header on all requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  /**
   * The auth string to send.
   */
  private authorization = ''

  /**
   * @param loginService - That sends us information about login state.
   */
  constructor(private loginService: LoginService) {
    this.loginService.loggedInState.subscribe({
        next: (state: LoggedInState) => {
          // console.log('Auth service received token?', state.accessToken);
          // Only act on exactly this message.
          if (state.loggedIn === false && state.state === LoggedInStatus.IN_PROGRESS && state.accessToken) {
            this.authorization = `Bearer ${state.accessToken}`
            if (state.interceptorReady !== true) {
              this.loginService.interceptorReady(true)
            }
          }
        }
      }
    )
  }

  /**
   * Intercept all requests and add auth token. We should however
   * do this only on white listed requests so that we do not
   * send our tokens when not needed.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', this.authorization)
    })
    return next.handle(authReq)
  }
}
