import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'

export const routes: Routes = [
  {
    path: 'base',
    loadChildren: () => import('./base/base.module').then((m) => m.BaseModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, useHash: true,
    scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
